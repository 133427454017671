import { formattedPhoneNumber } from "@/src/misc"
import rpc from "@/src/rpc"
import { sendMessage } from "@/src/useMessage"
import { useState } from "react"
import LoadingOverlay from "./LoadingOverlay"
import LoadingSpinner from "./LoadingSpinner"
import useCurrentOrg from "@/src/useCurrentOrg"
import { reloadSessionOrg } from "@/src/session"


export default function OrgForm ({onClose, afterSave, hideAddress}) {

  const org = useCurrentOrg()

  if (!org) return <LoadingSpinner size="medium"/>

  return <Form org={org} onClose={onClose} afterSave={afterSave} hideAddress={hideAddress}/>
}


function Form ({org, onClose, afterSave, hideAddress}) {
  const [saving, setSaving] = useState(false)

  const [city,      setCity     ] = useState(org.city      || '')
  const [name,      setName     ] = useState(org.name      || '')
  const [state,     setState    ] = useState(org.state     || '')
  const [street,    setStreet   ] = useState(org.street    || '')
  const [zip,       setZip      ] = useState(org.zip       || '')
  const [phone,     setPhone    ] = useState(org.phone     || '')

  async function handleSubmit (e) {
    e.preventDefault()

    let data = ({
      ...org,
      name,
      phone
    })

    if (!hideAddress) {
      data = {
        ...data,
        city,
        state,
        street,
        zip,
      }
    }

    try {
      setSaving(true)
      await rpc('orgs.updateInfo', {data}, {throwErrors: true})
      await reloadSessionOrg()
      sendMessage('orgUpdated')
    } catch (err) {
      console.error(err)
      sendMessage('error', "Couldn't update company.")
    } finally {
      setSaving(false)
      if (onClose) onClose()
      if (afterSave) afterSave()
    }
  }

  return <>
    <LoadingOverlay visible={saving}/>

    <form method="POST" onSubmit={handleSubmit}>

      <section>
        <div className="field">
          <label className="label">
            Company Name
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="organization"
              autoComplete="organization"
              value={name}
              onInput={e => setName(e.currentTarget.value)}
              placeholder="Acme Services Inc."
            />
          </div>
        </div>
      </section>

      { !hideAddress &&
        <>
          <section>
            <div className="field">
              <label className="label">
                Company Address
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="address-line1"
                  autoComplete="address-line1"
                  value={street}
                  onInput={e => setStreet(e.currentTarget.value)}
                  placeholder="123 Main St"
                />
              </div>
            </div>
          </section>

          <section className="spread mt-2">
            <div className="field">
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="city"
                  autoComplete="address-level2"
                  value={city}
                  onInput={e => setCity(e.currentTarget.value)}
                  placeholder="City"
                />
              </div>
            </div>

            <div className="field" style={{flexBasis: 80, marginLeft: 8}}>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="state"
                  maxLength="2"
                  autoComplete="address-level1"
                  autoCorrect="off"
                  autoCapitalize="characters"
                  value={state}
                  onInput={e => setState(e.currentTarget.value)}
                  placeholder="NY"
                />
              </div>
            </div>

            <div className="field" style={{flexBasis: 120, marginLeft: 8}}>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="postal-code"
                  autoComplete="postal-code"
                  pattern="^([0-9]{5})|([0-9]{5}-[0-9]{4})|([A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d)$"
                  value={zip}
                  onInput={e => setZip(e.currentTarget.value)}
                  placeholder="12345"
                />
              </div>
            </div>
          </section>
        </>
      }

      <section>
        <div className="field">
          <label className="label">
            Company Phone Number
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="phone"
              inputMode="tel"
              autoComplete="phone"
              value={phone}
              onInput={e => setPhone(formattedPhoneNumber(e.currentTarget.value))}
              placeholder="555-123-4567"
            />
          </div>
        </div>
      </section>

      <br/>

      <div className="button-container">
        <button className="button is-primary">
          Save
        </button>
        { !!onClose &&
          <button className="button" onClick={onClose}>
            Cancel
          </button>
        }
      </div>
    </form>

    <style jsx>{`
      form {
        text-align: left;
      }
      .field {
        margin-bottom: 0;
      }
      section {
        margin-top: 1rem;
      }
      .note {
        margin: 1rem 0;
        font-size: 0.9rem;
      }
      button {
        min-width: 140px;
      }
      label {
        margin-bottom: 0.2rem !important;
        display: block;
      }
    `}</style>
  </>
}
